import React from "react";
import Image from "next/image";
import Link from "next/link";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

interface Props {
  email: string;
  password: string;
  isAccept: boolean;
  setIsAccept: (isAccept: boolean) => void;
  setEmail: (mail: string) => void;
  setPassword: (pass: string) => void;
  handleLogin: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  imgAssets?: any;
  validateLogin: IValidateLogin<any>;
}
export interface IValidateLogin<T extends object> {
  email: T;
  password: T;
  isAccept?: T;
  isFormValid : boolean;
}
const LoginForm: React.FC<Props> = ({
  email,
  password,
  setEmail,
  isAccept,
  setIsAccept,
  setPassword,
  handleLogin,
  isLoading,
  imgAssets,
  validateLogin
}) => {
  return (
    <form onSubmit={handleLogin}>
      <section className="page_login">
        <div className="container login">
          <div className="text_center mb20">
            <Image className="mb30" src={imgAssets.logo2} alt="EVM" />
            <p><Image height={28} src={imgAssets.testEVMblue} alt="EVM" /></p>
            <p className="f18 mt40">Eviアカウントでログインしてください
            <OverlayTrigger
            placement="right-start"
            overlay={
              <Tooltip id="tooltip-2">
                <>
                  Eviノート、Evi計りのアカウントをお持ちの技工所様は同じメールアド<br/>
                  レスとパスワードでログインが可能です。<br/>
                  初めてEVMへログインする際は、管理者アカウントでの利用規約へ<br/>
                  の同意が必要です。
                </>
              </Tooltip>
              }
            >
            <span className="ml15 cursorPointer"><Image className="mr15" src={imgAssets.iconQ} alt='' /></span>
            </OverlayTrigger>
            </p>
          </div>
          <div className="formlogin mb20">
            <div className="form-group mb20">
              <input className="form-control form-controlh48" type="email" placeholder="メールアドレス" value={email} onChange={(event) => setEmail(event.target.value)} />
              {validateLogin.email?.isValid === false && validateLogin.email?.message}
            </div>
            <div className="form-group mb15">
              <div className="fsc boxpassword">
                <input className="form-control pass_log_id form-controlh48" type="password" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="パスワード" />
                {/* {validateLogin.password?.isValid === false && validateLogin.password?.message} */}
              </div>
            </div>
            <div className="form-group form-check mb15">
              <input className="form-check-input" id="SkipcheckEmail" type="checkbox" checked={isAccept} onChange={(event) => setIsAccept(event.target.checked)} />
              <label className="form-check-label f14" htmlFor="SkipcheckEmail">次回からメールアドレスの入力を省略</label>
            </div>
            <div className="text_center">
              <button type="submit"  className="btn btn-primary text_bold">ログイン</button>
              {/* disabled={isLoading || !validateLogin.isFormValid} */}
            </div>
          </div>
          <div className="text_center">
            <p className="mb15 alink"><Link href="/login">パスワードを忘れた方はこちら</Link></p>
            <p className="alink"><Link href="/login/qr">QRコードでログイン</Link></p>
          </div>
          <h2 className="titline mt40"><span className="text_bold">はじめての方はこちら</span></h2>
          <div className="formbox_e mt20 text_center"><Link className="btn btn-outline-secondary" href="/register">Eviアカウント新規登録</Link></div>
        </div>
      </section>
    </form>
  );
};
export default LoginForm;
