import { useLoginProcess } from "@/components/login/useLoginProcess";
import LoginForm from "@/components/login";
import { memo, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { getServerSidePropsHOC } from "@/middleware/auth";
import { ModalComfirm } from "@/components/modals/setting/labo/modalCustum";
import { Modal } from "@/helpers/modal";

export type Props = {      
  [key: string]: any
};
const LoginPage = memo((props: Props) => {
  const { email, password, isLoading, setEmail, setPassword, handleLogin, validateLogin, isAccept, setIsAccept} = useLoginProcess();
  const { handleModle  } = new Modal()

  return (
    <div>  
      <ModalComfirm
       {...props}
        modalId="conflictDevice" 
        modalType="custom"
        custum={{ class: "modalAlert" }}
      >
        <div>
          <div className="modal-body">
            <div className="ico text_center"> <Image src={ props?.imgAssets.icoExcla} alt="" /> </div>
            <h4 className="text_center title f20">エラー</h4>
            <p>
              すでに他の端末でログインしているためログインできません。<br />
              ログインしている端末からログアウトしてから再度ログインをお試しください。<br />
            </p>
          </div>
          <div className="modal-footer dflex dflex_center">
          <button data-request="close" data-modal="conflictDevice" onClick={handleModle} className="btn btn-outline-secondary minw140" type="button" > OK </button>
          </div>
        </div>
      </ModalComfirm>   
      <LoginForm      
        email={email}
        password={password}
        setEmail={setEmail}
        setPassword={setPassword}
        handleLogin={handleLogin}
        isAccept={isAccept}
        setIsAccept={setIsAccept}
        isLoading={isLoading}
        imgAssets={props.imgAssets}
        validateLogin={validateLogin}
      />
    </div>
  );
});
LoginPage.displayName = "LoginPage";
export const getServerSideProps = async (context: any) => {
  return getServerSidePropsHOC(context);
};
export default LoginPage;